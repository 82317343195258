import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const ContactForm = loadable(() => import("@organisms/ContactForm"));
const SectionHeading = loadable(() => import("@organisms/SectionHeading"));
const EndofPageCta = loadable(() => import("@organisms/EndofPageCta"));

const HomePage = ({ data, ...rest }) => {
  const { hero, form } = data;

  return (
    <PageContainer {...rest} flex={false}>
      <SectionHeading {...hero} variant="md" size="h1" descriptorSize="sm" />
      <ContactForm {...form} />
      <EndofPageCta />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
