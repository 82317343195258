/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/ContactPage";
import resolveImage from "@dataResolvers/resolveImage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ContactPageQuery {
    craft {
      entry(section: "contact") {
        ... on Craft_contact_contact_Entry {
          title
          slug
          url
          # Hero
          heading0
          descriptor0
          # Form
          heading2
          heading1
          # Meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    url,
    slug,
    // Hero
    heading0,
    descriptor0,
    // Form
    heading2,
    heading1,
    // Meta
    metaTitle,
    metaImage,
    metaDescription,
  } = entry;

  return {
    hero: { heading: heading0, descriptor: descriptor0 },
    form: { heading: heading2, formId: heading1 },
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ContactQuery"
    {...props}
  />
);

export default Page;
